import { Injectable } from '@angular/core';
import { TariffManagementStorageService } from '@core/services/tariff/persistence/tariff-management-storage.service';
import { TariffService } from '@core/services/tariff/tariff.service';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { ErrorModel } from '@shared/models/error';
import { ListZoneCitiesResp, Tariff } from '@xpo-ltl/sdk-tariffmanagement';
import { TransformData } from 'app/business/utils/transform-data/transform-data.utils';
import { of } from 'rxjs';
import { catchError, delay, exhaustMap, map } from 'rxjs/operators';
import { TariffManagementActions } from '../actions/tariff-management.actions';

@Injectable()
export class TariffManagementEffects {
  getTariffs$ = createEffect(() =>
    this.actions$.pipe(
      ofType(TariffManagementActions.getTariff),
      exhaustMap(() => {
        return this.tariffService.getTariffs().pipe(
          map((tariffs: Tariff[]) => {
            const tariffList: Tariff[] = TransformData.sortTariffByName(tariffs);
            return TariffManagementActions.getTariffSuccess({ tariffList });
          }),
          catchError((errors: ErrorModel[]) => of(TariffManagementActions.getTariffFail({ errors })))
        );
      })
    )
  );

  geZoneCities$ = createEffect(() =>
    this.actions$.pipe(
      ofType(TariffManagementActions.getZoneCities),
      exhaustMap(() => {
        // first check if the needed data is in the local storage in order to avoid to call the api to get the same result
        const listZoneCitiesResp: ListZoneCitiesResp = this.tariffManagementStorageService.getZoneCities();
        if (listZoneCitiesResp) {
          return of(listZoneCitiesResp).pipe(
            delay(1), // the delay is necessary for the ngrx engine works properly
            map((listZoneCitiesResp: ListZoneCitiesResp) => TariffManagementActions.getZoneCitiesSuccess({ listZoneCitiesResp }))
          );
        }

        // if the needed data is not in the local storage it is necessary to call the location API
        return this.tariffService.getZoneCities().pipe(
          map((listZoneCitiesResp: ListZoneCitiesResp) => {
            this.tariffManagementStorageService.saveZoneCities(listZoneCitiesResp.zoneCities);
            return TariffManagementActions.getZoneCitiesSuccess({ listZoneCitiesResp })
          }
          ),
          catchError((errors: ErrorModel[]) => of(TariffManagementActions.getZoneCitiesFail({ errors })))
        );
      })
    )
  );

  constructor(private actions$: Actions, public tariffService: TariffService, private tariffManagementStorageService: TariffManagementStorageService) { }
}
