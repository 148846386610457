import { Injectable } from '@angular/core';
import { LocalStorageService } from '@core/services/persistence/local-storage/local-storage.service';
import { ListZoneCitiesResp, ZoneCity } from '@xpo-ltl/sdk-tariffmanagement';
import { TariffManagementEnum } from './tariff-management-storage.enum';

@Injectable({
  providedIn: 'root'
})
export class TariffManagementStorageService {

  constructor(private localStorageService: LocalStorageService) { }

  saveZoneCities(zoneCities: ZoneCity[]): void {
    if (!zoneCities || zoneCities.length === 0) { return; }
    const zoneCitiesToSave: string = JSON.stringify(zoneCities);
    this.localStorageService.saveData(TariffManagementEnum.zoneCities, zoneCitiesToSave);
  }

  getZoneCities(): ListZoneCitiesResp {
    const zoneCitiesStored: string = this.localStorageService.getData(TariffManagementEnum.zoneCities);
    if (!zoneCitiesStored) { return; }

    const listZoneCitiesResp = new ListZoneCitiesResp();
    listZoneCitiesResp.zoneCities = JSON.parse(zoneCitiesStored);

    return listZoneCitiesResp;
  }
}
