import { createAction, props } from '@ngrx/store';
import { ErrorModel } from '@shared/models/error';
import { ListZoneCitiesResp, Tariff } from '@xpo-ltl/sdk-tariffmanagement';

export abstract class TariffManagementActions {
  static getTariff = createAction('[TariffManagement] Get the list of tariffs');
  static getTariffSuccess = createAction(
    '[TariffManagement] Get the list of tariffs Success',
    props<{ tariffList: Tariff[] }>()
  );
  static getTariffFail = createAction(
    '[TariffManagement] Get the list of tariffs Fail',
    props<{ errors: ErrorModel[] }>()
  );

  static getZoneCities = createAction('[TariffManagement] Get the list of cities');
  static getZoneCitiesSuccess = createAction(
    '[TariffManagement] Get the list of cities Success',
    props<{ listZoneCitiesResp: ListZoneCitiesResp }>()
  );
  static getZoneCitiesFail = createAction(
    '[TariffManagement] Get the list of cities Fail',
    props<{ errors: ErrorModel[] }>()
  );
}
