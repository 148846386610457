import { createSelector } from '@ngrx/store';
import { ListRateQuotesResp, RateQuoteRequest } from '@xpo-ltl-2.0/sdk-rating';
import { rateQuoteAdapter, rateQuoteFeatureSelector, RateQuoteState } from '../reducers/rate-quote.reducer';

const { selectAll } = rateQuoteAdapter.getSelectors();

const rateQuoteList = createSelector(rateQuoteFeatureSelector, selectAll);

const rateQuoteListWithListInfo = createSelector(
  rateQuoteList,
  rateQuoteFeatureSelector,
  (rateQuoteRequests: RateQuoteRequest[], state: RateQuoteState) => {
    const listRateQuotesResp: ListRateQuotesResp = { rateQuoteRequests, listInfo: state.listInfo };
    return listRateQuotesResp;
  }
);

const loadingGetRateQuoteList = createSelector(
  rateQuoteFeatureSelector,
  (state: RateQuoteState) => state.loadingGetRateQuoteList
);

const loadingGetRateQuotePdf = createSelector(
  rateQuoteFeatureSelector,
  (state: RateQuoteState) => state.loadingGetRateQuotePdf
);

const loadingGetCustomers = createSelector(
  rateQuoteFeatureSelector,
  (state: RateQuoteState) => state.loadingGetCustomers
);

const loadingCreateRateQuote = createSelector(
  rateQuoteFeatureSelector,
  (state: RateQuoteState) => state.loadingCreateRateQuote
);

const loadingCreateRateQuoteUpsell = createSelector(
  rateQuoteFeatureSelector,
  (state: RateQuoteState) => state.loadingCreateRateQuoteUpsell
);

const cancellUpsells = createSelector(
  rateQuoteFeatureSelector,
  (state: RateQuoteState) => state.cancellUpsells
);

const getSelectedCustomers = createSelector(
  rateQuoteFeatureSelector,
  (state: RateQuoteState) => state.selectedCustomer
);

const getReactiveFieldMessages = createSelector(
  rateQuoteFeatureSelector,
  (state: RateQuoteState) => state.reactiveFieldMessages
);

const getResultFile = createSelector(rateQuoteFeatureSelector, (state: RateQuoteState) => state.rateQuotePDF);

const createdRateQuote = createSelector(rateQuoteFeatureSelector, (state: RateQuoteState) => state.createdRateQuote);

const createdSpotQuote = createSelector(rateQuoteFeatureSelector, (state: RateQuoteState) => state.createdSpotQuote);
const addNewSpotQuoteOption = createSelector(rateQuoteFeatureSelector, (state: RateQuoteState) => state.addNewSpotQuoteOptionEnabled);

const quoteSelectedType = createSelector(rateQuoteFeatureSelector, (state: RateQuoteState) => state.quoteSelectedType);

const getRateQuoteDetail = createSelector(rateQuoteFeatureSelector, (state: RateQuoteState) => state.rateQuoteDetail);

const getRateQuoteDetailFromShipment = createSelector(rateQuoteFeatureSelector, (state: RateQuoteState) => state.rateQuoteDetailFromShipment);

const isRestrictedAccount = createSelector(
  rateQuoteFeatureSelector,
  (state: RateQuoteState) => state.isRestrictedAccount
);

const disableInternationalAccessorials = createSelector(rateQuoteFeatureSelector, (state: RateQuoteState) => state.disableInternationalAccessorials);

const isRecalculateRateQuote = createSelector(rateQuoteFeatureSelector, (state: RateQuoteState) => state.isRecalculateRateQuote);

const createInternationalRateQuoteResp = createSelector(rateQuoteFeatureSelector, (state: RateQuoteState) => state.createInternationalRateQuoteResp);

export const RateQuoteSelectors = {
  loadingGetCustomers,
  loadingGetRateQuoteList,
  rateQuoteListWithListInfo,
  getSelectedCustomers,
  loadingGetRateQuotePdf,
  getReactiveFieldMessages,
  getResultFile,
  createdRateQuote,
  createdSpotQuote,
  addNewSpotQuoteOption,
  quoteSelectedType,
  getRateQuoteDetail,
  getRateQuoteDetailFromShipment,
  isRestrictedAccount,
  disableInternationalAccessorials,
  loadingCreateRateQuote,
  loadingCreateRateQuoteUpsell,
  cancellUpsells,
  isRecalculateRateQuote,
  createInternationalRateQuoteResp
};
