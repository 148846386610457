import { FormGroup } from '@angular/forms';
import {
  RateQuoteOtherAccessorialCode,
  RateQuoteSelectInterface,
  ShipOptionsInterface
} from '@shared/models/rate-quote';
import { GetRateQuoteForShipmentResp, GetRateQuoteResp } from '@xpo-ltl-2.0/sdk-rating';
import { ChargeToCd, PricingRulesetOwnerCd, WeightUomCd } from '@xpo-ltl/sdk-common';
import { OffshoreOptionsDialogFormName } from '../../add-quote-details/shared/components/dialog/offshore-options/offshore-options-dialog.config';

// GENERAL ################################################################################################
export enum AddQuoteFormFieldsNames {
  customerInformation = 'customerInformation',
  paymentTermInformation = 'paymentTermInformation',
  pickupInformation = 'pickupInformation',
  deliveryInformation = 'deliveryInformation',
  commodityInformation = 'commodityInformation',
  otherSpecialServices = 'otherSpecialServices',
  comments = 'comments',
  accountInformation = 'accountInformation',
  specialServicesInternational = 'specialServicesInternational',
  internationalShippingInformation = 'internationalShippingInformation',
  internationalCommodity = 'internationalCommodity',
}

export interface AddQuoteFormObject {
  [AddQuoteFormFieldsNames.pickupInformation]: FormGroup;
  [AddQuoteFormFieldsNames.deliveryInformation]: FormGroup;
  [AddQuoteFormFieldsNames.commodityInformation]: FormGroup;
  [AddQuoteFormFieldsNames.otherSpecialServices]: FormGroup;
  [AddQuoteFormFieldsNames.comments]: any;
}

export interface AddQuoteCustomerShipmentFormObject extends AddQuoteFormObject {
  [AddQuoteFormFieldsNames.customerInformation]: FormGroup;
}

export interface AddQuoteGenericFormObject extends AddQuoteFormObject {
  [AddQuoteFormFieldsNames.paymentTermInformation]: FormGroup;
}

export interface AddQuoteCustomerInternationalFormObject {
  [AddQuoteFormFieldsNames.accountInformation]: FormGroup;
  [AddQuoteFormFieldsNames.internationalCommodity]: FormGroup;
  [AddQuoteFormFieldsNames.specialServicesInternational]: FormGroup;
  [AddQuoteFormFieldsNames.comments]: any;
}

export interface AddQuoteGenericInternationalFormObject {
  [AddQuoteFormFieldsNames.internationalShippingInformation]: FormGroup;
  [AddQuoteFormFieldsNames.internationalCommodity]: FormGroup;
  [AddQuoteFormFieldsNames.specialServicesInternational]: FormGroup;
  [AddQuoteFormFieldsNames.comments]: any;
}

const RateQuoteListText = {
  liftGateService: ' Liftgate Hand-Unload',
  sorting: 'Sorting/Segregating',
  notification: 'Notification Prior to Delivery',
  inside: 'Inside',
  exhibition: 'Exhibition',
  residential: 'Residential/Limited Access',
  containerStation: 'Container Station',
  construction: 'Construction / Utility',
  mineGovAirp: 'Mine / Gov’t. / Airport',
  appointment: 'Appointment',
  timeDateCritical: 'Time / Date Critical',
  holidayWeekend: 'Holiday / Weekend',
  afterBusinessHoursDelivery: 'After Business Hours Delivery',
  consolidation: 'Consolidation Handling',
  reconsignment: 'Reconsignment',
  redelivery: 'Redelivery',
  piers: 'Piers / Wharves Unloading',
  piersLoading: 'Piers / Wharves Loading',
  inspection: 'Inspection of Shipment',
  weigh: 'Weigh / Reweigh Shipment',
  inBondFreight: 'In Bond Freight',
  inBondTirCarnet: 'In Bond TIR Carnet',
  inBondCustomsInspectionDelay: 'In Bond Customs Inspection Delay',
  inBondCustomsRelForms: 'In Bond Customs Rel. Forms',
  SufferanceNbOnQc: 'Sufferance (Canada): NB, ON, QC',
  SufferanceOther: 'Sufferance (Canada): Other',
  SufferanceRemanifest: 'Sufferance Re-manifest',
  SufferanceHandling: 'Sufferance Handling',
  SufferanceExamination: 'Sufferance Examination',
  SufferanceInspectionDelay: 'Sufferance Inspection Delay',
  instantGuaranteedService: 'Instant Guaranteed Service (G!)',
  guaranteedByNoon: 'Guaranteed by Noon (G!12)',
  blindShipment: 'Blind Shipment',
  correctiontoBol: 'Correction to BOL',
  codCancelChange: 'C.O.D. Cancel / Change',
  returnUndeliveredShipment: 'Return Undelivered Shipment',
  returnCheck: 'Return Check',
  rapidRemoteService: 'Rapid Remote Service',
  excessiveLength: 'Excessive Length',
  excessiveValueCharge: 'Excessive Value Charge',
  freezableProtection: 'Freezable Protection',
};

export enum LooKupButtonId {
  pickUp = 'pickupInformationLookUpButton',
  delivery = 'deliveryInformationLookUpButton',
}

export enum AddQuoteCssClassNames {
  firstGroupList = 'first-group-list-element',
}

export type AddQuoteAccesorialsType =
  | AddQuoteOtherSpecialServicesMiscellaneousFieldsNames
  | AddQuotePickupSpecialServicesCommonFieldsNames
  | AddQuotePickupSpecialServicesOtherFieldsNames
  | AddQuoteDeliverySpecialServicesCommonFieldsNames
  | AddQuoteDeliverySpecialServicesOtherFieldsNames
  | AddQuoteOtherSpecialServicesInternationalFieldsNames
  | AddQuoteOtherSpecialServicesMiscellaneousFieldsNames
  | AddQuoteCmmodityInformationFormFieldsNames
  | AddQuoteCmmodityListInformationFormFieldsNames
  | RateQuoteOtherAccessorialCode
  | OffshoreOptionsDialogFormName
  | AddQuoteOtherSpecialServicesInternationalInternationalFieldsNames;

export const addCommodityDimentionsClassDefaultValue = '100';

export enum AddQuoteSpecialAccessorials {
  specialPricing = 'XSS',
  vsp = 'TLF',
}

// Payment Term #################################################################################################

export enum AddQuotePaymentTermsFormFieldsNames {
  paymentTerms = 'chargeToCd',
  discountLevel = 'overrideDiscountPct',
  tariff = 'overrideRatingTariff',
}
export interface RateQuotePaymentTermsInterface {
  prepaid: RateQuoteSelectInterface;
  collect: RateQuoteSelectInterface;
}

export enum PaymentTermsText {
  prepaid = 'Prepaid',
  collect = 'Collect',
}

export enum PaymentTermsShortValue {
  prepaid = 'P',
  collect = 'C',
}

export const RateQuotePaymentTerms: RateQuotePaymentTermsInterface = {
  prepaid: {
    value: ChargeToCd.PPD,
    text: PaymentTermsText.prepaid,
  },
  collect: {
    value: ChargeToCd.COLL,
    text: PaymentTermsText.collect,
  },
};

export const getRateQuotePaymentTermsTextFromValue = (value: RateQuotePaymentTermsInterface): PaymentTermsText => {
  for (const [_, objValue] of Object.entries(RateQuotePaymentTerms)) {
    if (objValue.value === value) {
      return objValue.text;
    }
  }
};

export const getPaymentTermsFromShortValue = (
  rateQuote: GetRateQuoteResp | GetRateQuoteForShipmentResp
): PaymentTermsText => {
  return rateQuote.quoteRequest.requesterPaymentTerms === PaymentTermsShortValue.prepaid
    ? (RateQuotePaymentTerms.prepaid.value as PaymentTermsText)
    : (RateQuotePaymentTerms.collect.value as PaymentTermsText);
};

export interface AddQuotePaymentTermsFormObject {
  [AddQuotePaymentTermsFormFieldsNames.discountLevel]: any;
  [AddQuotePaymentTermsFormFieldsNames.paymentTerms]: any;
  [AddQuotePaymentTermsFormFieldsNames.tariff]: any;
}

export const AddQuotePaymentTermsIds = {
  [AddQuotePaymentTermsFormFieldsNames.paymentTerms]: 'paymentTermsAddQuotePayment',
  [AddQuotePaymentTermsFormFieldsNames.tariff]: 'paymentTermsTariff',
  [AddQuotePaymentTermsFormFieldsNames.discountLevel]: 'discountLevelAddQuotePayment',
  prepaid: 'prepaidPaymentTermsAddQuotePayment',
  collect: 'collectDiscountLevelAddQuotePayment',
};

export const AddQuotePaymentTermsErrorIds = {
  [AddQuotePaymentTermsFormFieldsNames.paymentTerms]: 'paymentTermsAddQuotePaymentError',
  [AddQuotePaymentTermsFormFieldsNames.tariff]: 'paymentTermsTariffError',
  [AddQuotePaymentTermsFormFieldsNames.discountLevel]: 'discountLevelAddQuotePaymentError',
};

// CUSTOMER ###############################################################################################
export enum AddQuoteCustomerInformationFormFieldsNames {
  company = 'company',
  account = 'account',
  accountPostalCode = 'accountPostalCode',
  customerRole = 'customerRole',
  paymentTerms = 'paymentTerms',
}

export interface AddQuoteCustomerInformationFormObject {
  [AddQuoteCustomerInformationFormFieldsNames.company]: any;
  [AddQuoteCustomerInformationFormFieldsNames.account]: any;
  [AddQuoteCustomerInformationFormFieldsNames.accountPostalCode]: any;
  [AddQuoteCustomerInformationFormFieldsNames.customerRole]: any;
  [AddQuoteCustomerInformationFormFieldsNames.paymentTerms]: any;
}

export interface RateQuoteCustomerRoleType {
  shipper: RateQuoteSelectInterface;
  consignee: RateQuoteSelectInterface;
  thirdParty: RateQuoteSelectInterface;
}

export enum RateQUoteCustomerRoleTypeText {
  shipper = 'Shipper',
  consignee = 'Consignee',
  thirdParty = 'Third Party',
}

export enum RateQuoteCustomerRoleValueFromApi {
  shipper = '1',
  consignee = '2',
  thirdParty = '5',
}

export const RateQuoteCustomerRole: RateQuoteCustomerRoleType = {
  shipper: {
    value: PricingRulesetOwnerCd.SHIPPER,
    text: RateQUoteCustomerRoleTypeText.shipper,
  },
  consignee: {
    value: PricingRulesetOwnerCd.CONSIGNEE,
    text: RateQUoteCustomerRoleTypeText.consignee,
  },
  thirdParty: {
    value: PricingRulesetOwnerCd.BILL_TO,
    text: RateQUoteCustomerRoleTypeText.thirdParty,
  },
};

export function getCustomerShipperValueFromText(customerShipperValue: string): PricingRulesetOwnerCd {
  switch (customerShipperValue) {
    case RateQuoteCustomerRole.shipper.value:
    case RateQuoteCustomerRole.shipper.text:
      return RateQuoteCustomerRole.shipper.value as PricingRulesetOwnerCd;
    case RateQuoteCustomerRole.consignee.value:
    case RateQuoteCustomerRole.consignee.text:
      return RateQuoteCustomerRole.consignee.value as PricingRulesetOwnerCd;
    case RateQuoteCustomerRole.thirdParty.value:
    case RateQuoteCustomerRole.thirdParty.text:
      return RateQuoteCustomerRole.thirdParty.value as PricingRulesetOwnerCd;

    default:
      return RateQuoteCustomerRole.shipper.value as PricingRulesetOwnerCd;
  }
}

export const getRateQuoteCustomerShipperValueTextFromValue = (
  value: PricingRulesetOwnerCd
): RateQUoteCustomerRoleTypeText => {
  for (const [_, objValue] of Object.entries(RateQuoteCustomerRole)) {
    if (objValue.value === value) {
      return objValue.text;
    }
  }
};

export const getRateQuoteCustomerShipperFromApiValue = (roleApiValue: string) => {
  if (roleApiValue === RateQuoteCustomerRoleValueFromApi.shipper) {
    return PricingRulesetOwnerCd.SHIPPER;
  }
  if (roleApiValue === RateQuoteCustomerRoleValueFromApi.consignee) {
    return PricingRulesetOwnerCd.CONSIGNEE;
  }
  if (roleApiValue === RateQuoteCustomerRoleValueFromApi.thirdParty) {
    return RateQUoteCustomerRoleTypeText.thirdParty;
  }
};

export const AddQuoteCustomerInformationIds = {
  company: 'companyQuoteCustomer',
  account: 'accountQuoteCustomer',
  accountPostalCode: 'accountPostalCodeQuoteCustomer',
  customerRole: 'customerRoleQuoteCustomer',
  customerRoleShipper: 'customerRoleShipperQuoteCustomer',
  customerRoleConsignee: 'customerRoleConsigneeQuoteCustomer',
  customerRoleThridParty: 'customerRoleThridPartyQuoteCustomer',
  paymentTerms: 'paymentTermsQuoteCustomer',
  paymentTermsPrepaid: 'paymentTermsPrepaidQuoteCustomer',
  paymentTermsCollect: 'paymentTermsCollectQuoteCustomer',
};

// Pickup #################################################################################################
export enum AddQuotePickupInformationFormFieldsNames {
  originPostalCode = 'originZipNbr',
  effectiveShipmentDate = 'pickupDate',
  specialServicesCommon = 'specialPickupServicesCommon',
  specialServicesOther = 'specialPickupServicesOther',
}

export enum AddQuotePickupSpecialServicesCommonFieldsNames {
  inside = 'OIP',
  exhibition = 'PEO',
  liftGateService = 'OLG',
  containerStation = 'OCF',
  residential = 'RSO',
  sorting = 'OSS',
}

export enum AddQuotePickupSpecialServicesOtherFieldsNames {
  consolidation = 'HCB',
  inspection = 'CBI',
  reconsignment = 'ORC',
  construction = 'OCS',
  mineGovAirp = 'OMS',
  weigh = 'CBR',
  holidayWeekend = 'OSH',
  piers = 'OPW',
}
export interface AddQuotePickupInformationFormObject {
  [AddQuotePickupInformationFormFieldsNames.originPostalCode]: any;
  [AddQuotePickupInformationFormFieldsNames.effectiveShipmentDate]: any;
  [AddQuotePickupInformationFormFieldsNames.specialServicesCommon]: any;
  [AddQuotePickupInformationFormFieldsNames.specialServicesOther]: any;
}

export const AddQuotePickupInformationIds = {
  [AddQuotePickupInformationFormFieldsNames.originPostalCode]: 'originPostalCodeQuoteCustomer',
  [AddQuotePickupInformationFormFieldsNames.effectiveShipmentDate]: 'effectiveShipmentDateQuoteCustomer',
  [AddQuotePickupInformationFormFieldsNames.specialServicesCommon]: 'specialServicesCommonQuoteCustomer',
  [AddQuotePickupInformationFormFieldsNames.specialServicesOther]: 'specialServicesOtherQuoteCustomer',

  [AddQuotePickupSpecialServicesCommonFieldsNames.inside]: 'specialServicesCommonInside',
  [AddQuotePickupSpecialServicesCommonFieldsNames.exhibition]: 'specialServicesCommonExhibition',
  [AddQuotePickupSpecialServicesCommonFieldsNames.liftGateService]: 'specialServicesCommonLLiftGateService',
  [AddQuotePickupSpecialServicesCommonFieldsNames.containerStation]: 'specialServicesCommonContainerStation',
  [AddQuotePickupSpecialServicesCommonFieldsNames.residential]: 'specialServicesCommonResidential',
  [AddQuotePickupSpecialServicesCommonFieldsNames.sorting]: 'specialServicesCommonSorting',

  [AddQuotePickupSpecialServicesOtherFieldsNames.consolidation]: 'specialServicesOtherConsolidation',
  [AddQuotePickupSpecialServicesOtherFieldsNames.inspection]: 'specialServicesOtherInspection',
  [AddQuotePickupSpecialServicesOtherFieldsNames.reconsignment]: 'specialServicesOtherReconsignment',
  [AddQuotePickupSpecialServicesOtherFieldsNames.construction]: 'specialServicesOtherConstruction',
  [AddQuotePickupSpecialServicesOtherFieldsNames.mineGovAirp]: 'specialServicesOtherMineGovAirp',
  [AddQuotePickupSpecialServicesOtherFieldsNames.weigh]: 'specialServicesOtherWeigh',
  [AddQuotePickupSpecialServicesOtherFieldsNames.holidayWeekend]: 'specialServicesOtherHolidayWeekend',
  [AddQuotePickupSpecialServicesOtherFieldsNames.piers]: 'specialServicesOtherPiers',
};

export const AddQuotePickupInformationErrorIds = {
  [AddQuotePickupInformationFormFieldsNames.effectiveShipmentDate]: 'effectiveShipmentDateQuoteCustomerError',
  [AddQuotePickupInformationFormFieldsNames.originPostalCode]: 'originPostalCodeQuoteCustomerError',
};

export const AddQuotePickupSpecialServicesCommonIds = {
  [AddQuotePickupSpecialServicesCommonFieldsNames.inside]: 'insideQuoteCustomer',
  [AddQuotePickupSpecialServicesCommonFieldsNames.exhibition]: 'exhibitionQuoteCustomer',
  [AddQuotePickupSpecialServicesCommonFieldsNames.liftGateService]: 'liftGateServiceQuoteCustomer',
  [AddQuotePickupSpecialServicesCommonFieldsNames.containerStation]: 'containerStationQuoteCustomer',
  [AddQuotePickupSpecialServicesCommonFieldsNames.residential]: 'residentialQuoteCustomer',
  [AddQuotePickupSpecialServicesCommonFieldsNames.sorting]: 'sortingQuoteCustomer',
};

export const RateQuotePickupListText = {
  [AddQuotePickupInformationFormFieldsNames.specialServicesCommon]: {
    [AddQuotePickupSpecialServicesCommonFieldsNames.inside]: RateQuoteListText.inside,
    [AddQuotePickupSpecialServicesCommonFieldsNames.exhibition]: RateQuoteListText.exhibition,
    [AddQuotePickupSpecialServicesCommonFieldsNames.liftGateService]: RateQuoteListText.liftGateService,
    [AddQuotePickupSpecialServicesCommonFieldsNames.containerStation]: RateQuoteListText.containerStation,
    [AddQuotePickupSpecialServicesCommonFieldsNames.residential]: RateQuoteListText.residential,
    [AddQuotePickupSpecialServicesCommonFieldsNames.sorting]: RateQuoteListText.sorting,
  },
  [AddQuotePickupInformationFormFieldsNames.specialServicesOther]: {
    [AddQuotePickupSpecialServicesOtherFieldsNames.consolidation]: RateQuoteListText.consolidation,
    [AddQuotePickupSpecialServicesOtherFieldsNames.inspection]: RateQuoteListText.inspection,
    [AddQuotePickupSpecialServicesOtherFieldsNames.reconsignment]: RateQuoteListText.reconsignment,
    [AddQuotePickupSpecialServicesOtherFieldsNames.construction]: RateQuoteListText.construction,
    [AddQuotePickupSpecialServicesOtherFieldsNames.mineGovAirp]: RateQuoteListText.mineGovAirp,
    [AddQuotePickupSpecialServicesOtherFieldsNames.weigh]: RateQuoteListText.weigh,
    [AddQuotePickupSpecialServicesOtherFieldsNames.holidayWeekend]: RateQuoteListText.holidayWeekend,
    [AddQuotePickupSpecialServicesOtherFieldsNames.piers]: RateQuoteListText.piers,
  },
};

export const AddQuotePickupInformationTooltipsId = {
  [AddQuotePickupSpecialServicesCommonFieldsNames.exhibition]: 'pickupInformationCommonExhibitionTooltip',
  [AddQuotePickupSpecialServicesCommonFieldsNames.containerStation]: 'pickupInformationCommonContainerStationTooltip',
  [AddQuotePickupSpecialServicesCommonFieldsNames.sorting]: 'pickupInformationCommonSortingTooltip',

  [AddQuotePickupSpecialServicesOtherFieldsNames.consolidation]: 'pickupInformationOtherConsolidationTooltip',
  [AddQuotePickupSpecialServicesOtherFieldsNames.reconsignment]: 'pickupInformationOtherReconsignmentTooltip',
  [AddQuotePickupSpecialServicesOtherFieldsNames.construction]: 'pickupInformationOtherConstructionTooltip',
  [AddQuotePickupSpecialServicesOtherFieldsNames.holidayWeekend]: 'pickupInformationOtherHolidayWeekendTooltip',
  [AddQuotePickupSpecialServicesOtherFieldsNames.piers]: 'pickupInformationOtherPiersTooltip',
};

// Delivery Information #################################################################################################
export enum AddQuoteDeliveryInformationFormFieldsNames {
  destPostalCode = 'destinationZipNbr',
  specialDeliveryServicesCommon = 'specialDeliveryServicesCommon',
  specialDeliveryServicesOther = 'specialDeliveryServicesOther',
}

export enum AddQuoteDeliverySpecialServicesCommonFieldsNames {
  liftGateService = 'DLG',
  sorting = 'SRT',
  notification = 'DNC',
  inside = 'DID',
  exhibition = 'PED',
  residential = 'RSD',
  containerStation = 'DCF',
  construction = 'CSD',
  mineGovAirp = 'DMS',
  appointment = 'APT',
  timeDateCritical = 'TDC',
  holidayWeekend = 'DSH',
  afterBusinessHoursDelivery = 'DAH',
}

export enum AddQuoteDeliverySpecialServicesOtherFieldsNames {
  consolidation = 'HCC',
  reconsignment = 'RCD',
  redelivery = 'RED',
  piers = 'DPW',
}
export interface AddQuoteDeliveryInformationFormObject {
  [AddQuoteDeliveryInformationFormFieldsNames.destPostalCode]: any;
  [AddQuoteDeliveryInformationFormFieldsNames.specialDeliveryServicesCommon]: any;
  [AddQuoteDeliveryInformationFormFieldsNames.specialDeliveryServicesOther]: any;
}

export const RateQuoteDeliveryListText = {
  [AddQuoteDeliveryInformationFormFieldsNames.specialDeliveryServicesCommon]: {
    [AddQuoteDeliverySpecialServicesCommonFieldsNames.liftGateService]: RateQuoteListText.liftGateService,
    [AddQuoteDeliverySpecialServicesCommonFieldsNames.sorting]: RateQuoteListText.sorting,
    [AddQuoteDeliverySpecialServicesCommonFieldsNames.notification]: RateQuoteListText.notification,
    [AddQuoteDeliverySpecialServicesCommonFieldsNames.inside]: RateQuoteListText.inside,
    [AddQuoteDeliverySpecialServicesCommonFieldsNames.exhibition]: RateQuoteListText.exhibition,
    [AddQuoteDeliverySpecialServicesCommonFieldsNames.residential]: RateQuoteListText.residential,
    [AddQuoteDeliverySpecialServicesCommonFieldsNames.containerStation]: RateQuoteListText.containerStation,
    [AddQuoteDeliverySpecialServicesCommonFieldsNames.construction]: RateQuoteListText.construction,
    [AddQuoteDeliverySpecialServicesCommonFieldsNames.mineGovAirp]: RateQuoteListText.mineGovAirp,
    [AddQuoteDeliverySpecialServicesCommonFieldsNames.appointment]: RateQuoteListText.appointment,
    [AddQuoteDeliverySpecialServicesCommonFieldsNames.timeDateCritical]: RateQuoteListText.timeDateCritical,
    [AddQuoteDeliverySpecialServicesCommonFieldsNames.holidayWeekend]: RateQuoteListText.holidayWeekend,
    [AddQuoteDeliverySpecialServicesCommonFieldsNames.afterBusinessHoursDelivery]:
      RateQuoteListText.afterBusinessHoursDelivery,
  },
  [AddQuoteDeliveryInformationFormFieldsNames.specialDeliveryServicesOther]: {
    [AddQuoteDeliverySpecialServicesOtherFieldsNames.consolidation]: RateQuoteListText.consolidation,
    [AddQuoteDeliverySpecialServicesOtherFieldsNames.reconsignment]: RateQuoteListText.reconsignment,
    [AddQuoteDeliverySpecialServicesOtherFieldsNames.redelivery]: RateQuoteListText.redelivery,
    [AddQuoteDeliverySpecialServicesOtherFieldsNames.piers]: RateQuoteListText.piersLoading,
  },
};

export const AddQuoteDeliveryInformationIds = {
  [AddQuoteDeliveryInformationFormFieldsNames.destPostalCode]: 'destPostalCode',
  [AddQuoteDeliveryInformationFormFieldsNames.specialDeliveryServicesCommon]: 'specialDeliveryServicesCommon',
  [AddQuoteDeliveryInformationFormFieldsNames.specialDeliveryServicesOther]: 'specialDeliveryServicesOther',

  [AddQuoteDeliverySpecialServicesCommonFieldsNames.liftGateService]: 'deliverySpecialServicesCommonLiftGateService',
  [AddQuoteDeliverySpecialServicesCommonFieldsNames.sorting]: 'deliverySpecialServicesCommonSorting',
  [AddQuoteDeliverySpecialServicesCommonFieldsNames.notification]: 'deliverySpecialServicesCommonNotification',
  [AddQuoteDeliverySpecialServicesCommonFieldsNames.inside]: 'deliverySpecialServicesCommonInside',
  [AddQuoteDeliverySpecialServicesCommonFieldsNames.exhibition]: 'deliverySpecialServicesCommonExhibition',
  [AddQuoteDeliverySpecialServicesCommonFieldsNames.residential]: 'deliverySpecialServicesCommonResidential',
  [AddQuoteDeliverySpecialServicesCommonFieldsNames.containerStation]: 'deliverySpecialServicesCommonContainerStation',
  [AddQuoteDeliverySpecialServicesCommonFieldsNames.construction]: 'deliverySpecialServicesCommonConstruction',
  [AddQuoteDeliverySpecialServicesCommonFieldsNames.mineGovAirp]: 'deliverySpecialServicesCommonMineGovAirp',
  [AddQuoteDeliverySpecialServicesCommonFieldsNames.appointment]: 'deliverySpecialServicesCommonAppointment',
  [AddQuoteDeliverySpecialServicesCommonFieldsNames.timeDateCritical]: 'deliverySpecialServicesCommonTimeDateCritical',
  [AddQuoteDeliverySpecialServicesCommonFieldsNames.holidayWeekend]: 'deliverySpecialServicesCommonHolidayWeekend',
  [AddQuoteDeliverySpecialServicesCommonFieldsNames.afterBusinessHoursDelivery]:
    'deliverySpecialServicesCommonAfterBusinessHoursDelivery',

  [AddQuoteDeliverySpecialServicesOtherFieldsNames.consolidation]: 'deliverySpecialServicesOtherConsolidation',
  [AddQuoteDeliverySpecialServicesOtherFieldsNames.reconsignment]: 'deliverySpecialServicesOtherReconsignment',
  [AddQuoteDeliverySpecialServicesOtherFieldsNames.redelivery]: 'deliverySpecialServicesOtherRedelivery',
  [AddQuoteDeliverySpecialServicesOtherFieldsNames.piers]: 'deliverySpecialServicesOtherPpiers',
};

export const AddQuoteDeliveryInformationFormErrorIds = {
  [AddQuoteDeliveryInformationFormFieldsNames.destPostalCode]: 'destPostalCodeError',
};

export const AddQuoteDeliveryInformationFirstGroupListAttributes = {
  [AddQuoteDeliverySpecialServicesCommonFieldsNames.inside]: true,
  [AddQuoteDeliverySpecialServicesCommonFieldsNames.appointment]: true,
};

export const AddQuoteDeliveryInformationTooltipIds = {
  [AddQuoteDeliverySpecialServicesCommonFieldsNames.liftGateService]:
    'deliverySpecialServicesCommonLiftGateServiceTooltip',
  [AddQuoteDeliverySpecialServicesCommonFieldsNames.sorting]: 'deliverySpecialServicesCommonSortingTooltip',
  [AddQuoteDeliverySpecialServicesCommonFieldsNames.exhibition]: 'deliverySpecialServicesCommonExhibitionTooltip',
  [AddQuoteDeliverySpecialServicesCommonFieldsNames.containerStation]:
    'deliverySpecialServicesCommonContainerStationTooltip',
  [AddQuoteDeliverySpecialServicesCommonFieldsNames.construction]: 'deliverySpecialServicesCommonConstructionTooltip',
  [AddQuoteDeliverySpecialServicesCommonFieldsNames.appointment]: 'deliverySpecialServicesCommonAppointmentTooltip',
  [AddQuoteDeliverySpecialServicesCommonFieldsNames.holidayWeekend]:
    'deliverySpecialServicesCommonHolidayWeekendTooltip',
  [AddQuoteDeliverySpecialServicesCommonFieldsNames.timeDateCritical]:
    'deliverySpecialServicesCommonTimeDateCriticalTooltip',

  [AddQuoteDeliverySpecialServicesOtherFieldsNames.consolidation]: 'deliverySpecialServicesOtherConsolidationTooltip',
  [AddQuoteDeliverySpecialServicesOtherFieldsNames.reconsignment]: 'deliverySpecialServicesOtherReconsignmentTooltip',
  [AddQuoteDeliverySpecialServicesOtherFieldsNames.piers]: 'deliverySpecialServicesOtherPiersTooltip',
};

// Commodity Information ##################################################################################################
export enum AddQuoteCmmodityInformationFormFieldsNames {
  commodityList = 'commodityList',
  singleShipment = 'SSC',
  totalPalletCount = 'totalPalletCnt',
  totalLinealFeet = 'totalLinealFeet',
}

export enum AddQuoteCmmodityListInformationFormFieldsNames {
  class = 'class',
  weight = 'weight',
  length = 'length',
  width = 'width',
  height = 'height',
  numberOfUnits = 'numberOfUnits',
  commodity = 'commodity',
  nmfcCode = 'nmfcCode',
  hazmat = 'hazmat',
}

export interface AddQuoteCommodityInformationFormObject {
  [AddQuoteCmmodityInformationFormFieldsNames.commodityList]: any;
  [AddQuoteCmmodityInformationFormFieldsNames.singleShipment]: any;
  [AddQuoteCmmodityInformationFormFieldsNames.totalPalletCount]: any;
  [AddQuoteCmmodityInformationFormFieldsNames.totalLinealFeet]: any;
}

export interface AddQuoteCommodityInformationListFormObject {
  [AddQuoteCmmodityListInformationFormFieldsNames.class]: any;
  [AddQuoteCmmodityListInformationFormFieldsNames.weight]: any;
  [AddQuoteCmmodityListInformationFormFieldsNames.length]: any;
  [AddQuoteCmmodityListInformationFormFieldsNames.width]: any;
  [AddQuoteCmmodityListInformationFormFieldsNames.height]: any;
  [AddQuoteCmmodityListInformationFormFieldsNames.numberOfUnits]: any;
  [AddQuoteCmmodityListInformationFormFieldsNames.commodity]: any;
  [AddQuoteCmmodityListInformationFormFieldsNames.nmfcCode]: any;
  [AddQuoteCmmodityListInformationFormFieldsNames.hazmat]: any;
}

export const AddQuoteCmmodityInformationIds = {
  [AddQuoteCmmodityInformationFormFieldsNames.singleShipment]: 'singleShipmentQuoteCmmodity',
  [AddQuoteCmmodityInformationFormFieldsNames.totalPalletCount]: 'totalPalletCountQuoteCmmodity',
  [AddQuoteCmmodityInformationFormFieldsNames.totalLinealFeet]: 'totalLinealFeetQuoteCmmodity',
  addAnhotherCommodity: 'addAnhotherCommodity',
  totalPalletCountInfo: 'totalPalletCountInfo',
};

export const AddQuoteCmmodityInformationListIds = {
  [AddQuoteCmmodityListInformationFormFieldsNames.class]: 'classQuoteCommodityList',
  [AddQuoteCmmodityListInformationFormFieldsNames.weight]: 'weightQuoteCommodityList',
  [AddQuoteCmmodityListInformationFormFieldsNames.length]: 'lengthQuoteCommodityList',
  [AddQuoteCmmodityListInformationFormFieldsNames.width]: 'widthQuoteCommodityList',
  [AddQuoteCmmodityListInformationFormFieldsNames.height]: 'heightQuoteCommodityList',
  [AddQuoteCmmodityListInformationFormFieldsNames.numberOfUnits]: 'numberOfUnitsQuoteCommodityList',
  [AddQuoteCmmodityListInformationFormFieldsNames.commodity]: 'commodityQuoteCommodityList',
  [AddQuoteCmmodityListInformationFormFieldsNames.nmfcCode]: 'nmfcCodeQuoteCommodityList',
  [AddQuoteCmmodityListInformationFormFieldsNames.hazmat]: 'hazmatQuoteCommodityList',
};

export const AddQuoteCmmodityInformationListErrorIds = {
  [AddQuoteCmmodityListInformationFormFieldsNames.class]: 'classQuoteCommodityListError',
  [AddQuoteCmmodityListInformationFormFieldsNames.weight]: 'weightQuoteCommodityListError',
  [AddQuoteCmmodityListInformationFormFieldsNames.length]: 'lengthQuoteCommodityListError',
  [AddQuoteCmmodityListInformationFormFieldsNames.width]: 'widthQuoteCommodityListError',
  [AddQuoteCmmodityListInformationFormFieldsNames.height]: 'heightQuoteCommodityListError',
  [AddQuoteCmmodityListInformationFormFieldsNames.numberOfUnits]: 'numberOfUnitsQuoteCommodityListError',
  [AddQuoteCmmodityListInformationFormFieldsNames.commodity]: 'commodityQuoteCommodityListError',
  [AddQuoteCmmodityListInformationFormFieldsNames.nmfcCode]: 'nmfcCodeQuoteCommodityListError',
  [AddQuoteCmmodityListInformationFormFieldsNames.hazmat]: 'hazmatQuoteCommodityListError',
};

export const QuoteCommodityInformationDefaultValues = {
  [AddQuoteCmmodityListInformationFormFieldsNames.class]: addCommodityDimentionsClassDefaultValue,
};

export const NewCommodityInformationList: AddQuoteCommodityInformationListFormObject = {
  [AddQuoteCmmodityListInformationFormFieldsNames.class]: [
    QuoteCommodityInformationDefaultValues[AddQuoteCmmodityListInformationFormFieldsNames.class],
  ],
  [AddQuoteCmmodityListInformationFormFieldsNames.weight]: [null],
  [AddQuoteCmmodityListInformationFormFieldsNames.length]: [null],
  [AddQuoteCmmodityListInformationFormFieldsNames.width]: [null],
  [AddQuoteCmmodityListInformationFormFieldsNames.height]: [null],
  [AddQuoteCmmodityListInformationFormFieldsNames.numberOfUnits]: [null],
  [AddQuoteCmmodityListInformationFormFieldsNames.commodity]: [null],
  [AddQuoteCmmodityListInformationFormFieldsNames.nmfcCode]: [null],
  [AddQuoteCmmodityListInformationFormFieldsNames.hazmat]: [null],
};

export const AddQuoteCmmodityInformationTooltipIds = {
  [AddQuoteCmmodityListInformationFormFieldsNames.hazmat]: 'hazmatQuoteCommodityListTooltipId',
};

// Other Special Services #################################################################################################
export enum AddQuoteOtherSpecialServicesFormFieldsNames {
  internationalServices = 'internationalServices',
  miscellaneousServices = 'miscellaneousServices',
}

export enum AddQuoteOtherSpecialServicesInternationalFieldsNames {
  inBondFreight = 'CIB',
  inBondTirCarnet = 'IBC',
  inBondCustomsInspectionDelay = 'IBI',
  inBondCustomsRelForms = 'WAA',
  sufferanceNbOnQc = 'SWC',
  sufferanceOther = 'SWR',
  sufferanceRemanifest = 'SWD',
  sufferanceHandling = 'SWH',
  sufferanceExamination = 'SWE',
  sufferanceInspectionDelay = 'SWP',
}

export enum AddQuoteOtherSpecialServicesMiscellaneousFieldsNames {
  instantGuaranteedService = 'GUR',
  guaranteedByNoon = 'G12',
  blindShipment = 'BLS',
  correctiontoBol = 'CBL',
  codCancelChange = 'CCD',
  returnUndeliveredShipment = 'RET',
  returnCheck = 'RCF',
  rapidRemoteService = 'RRS',
  excessiveLength = 'ELS',
  excessiveValueCharge = 'EVC',
  freezableProtection = 'XFZ',
}

export enum AddQuoteOtherSpecialServicesMiscellaneousFieldsNamesJoin {
  instantGuaranteedRapidRemote = 'GUR_RRS',
}

export enum AddQuoteOtherSpecialServicesMiscellaneousInputFieldsNames {
  excessiveLengthList = 'excessiveLengthList',
  excessiveValueChargeValue = 'excessiveValueChargeValue',
}

export interface AddQuoteOtherSpecialServicesFormObject {
  [AddQuoteOtherSpecialServicesFormFieldsNames.internationalServices]: any;
  [AddQuoteOtherSpecialServicesFormFieldsNames.miscellaneousServices]: any;
}

export const RateQuoteOtherSpecialServicesListText = {
  [AddQuoteOtherSpecialServicesFormFieldsNames.internationalServices]: {
    [AddQuoteOtherSpecialServicesInternationalFieldsNames.inBondFreight]: RateQuoteListText.inBondFreight,
    [AddQuoteOtherSpecialServicesInternationalFieldsNames.inBondTirCarnet]: RateQuoteListText.inBondTirCarnet,
    [AddQuoteOtherSpecialServicesInternationalFieldsNames.inBondCustomsInspectionDelay]:
      RateQuoteListText.inBondCustomsInspectionDelay,
    [AddQuoteOtherSpecialServicesInternationalFieldsNames.inBondCustomsRelForms]:
      RateQuoteListText.inBondCustomsRelForms,
    [AddQuoteOtherSpecialServicesInternationalFieldsNames.sufferanceNbOnQc]: RateQuoteListText.SufferanceNbOnQc,
    [AddQuoteOtherSpecialServicesInternationalFieldsNames.sufferanceOther]: RateQuoteListText.SufferanceOther,
    [AddQuoteOtherSpecialServicesInternationalFieldsNames.sufferanceRemanifest]: RateQuoteListText.SufferanceRemanifest,
    [AddQuoteOtherSpecialServicesInternationalFieldsNames.sufferanceHandling]: RateQuoteListText.SufferanceHandling,
    [AddQuoteOtherSpecialServicesInternationalFieldsNames.sufferanceExamination]:
      RateQuoteListText.SufferanceExamination,
    [AddQuoteOtherSpecialServicesInternationalFieldsNames.sufferanceInspectionDelay]:
      RateQuoteListText.SufferanceInspectionDelay,
  },
  [AddQuoteOtherSpecialServicesFormFieldsNames.miscellaneousServices]: {
    [AddQuoteOtherSpecialServicesMiscellaneousFieldsNames.instantGuaranteedService]:
      RateQuoteListText.instantGuaranteedService,
    [AddQuoteOtherSpecialServicesMiscellaneousFieldsNames.guaranteedByNoon]: RateQuoteListText.guaranteedByNoon,
    [AddQuoteOtherSpecialServicesMiscellaneousFieldsNames.blindShipment]: RateQuoteListText.blindShipment,
    [AddQuoteOtherSpecialServicesMiscellaneousFieldsNames.correctiontoBol]: RateQuoteListText.correctiontoBol,
    [AddQuoteOtherSpecialServicesMiscellaneousFieldsNames.codCancelChange]: RateQuoteListText.codCancelChange,
    [AddQuoteOtherSpecialServicesMiscellaneousFieldsNames.returnUndeliveredShipment]:
      RateQuoteListText.returnUndeliveredShipment,
    [AddQuoteOtherSpecialServicesMiscellaneousFieldsNames.returnCheck]: RateQuoteListText.returnCheck,
    [AddQuoteOtherSpecialServicesMiscellaneousFieldsNames.rapidRemoteService]: RateQuoteListText.rapidRemoteService,
    [AddQuoteOtherSpecialServicesMiscellaneousFieldsNames.excessiveLength]: RateQuoteListText.excessiveLength,
    [AddQuoteOtherSpecialServicesMiscellaneousFieldsNames.excessiveValueCharge]: RateQuoteListText.excessiveValueCharge,
    [AddQuoteOtherSpecialServicesMiscellaneousFieldsNames.freezableProtection]: RateQuoteListText.freezableProtection,
  },
};

export const AddQuoteOtherSpecialServicesIds = {
  [AddQuoteOtherSpecialServicesFormFieldsNames.internationalServices]: 'otherServicesInternationalServices',
  [AddQuoteOtherSpecialServicesFormFieldsNames.miscellaneousServices]: 'otherServicesMiscellaneousServices',

  [AddQuoteOtherSpecialServicesInternationalFieldsNames.inBondFreight]: 'otherServicesInBondFreight',
  [AddQuoteOtherSpecialServicesInternationalFieldsNames.inBondTirCarnet]: 'otherServicesInBondTirCarnet',
  [AddQuoteOtherSpecialServicesInternationalFieldsNames.inBondCustomsInspectionDelay]:
    'otherServicesInBondCustomsInspectionDelay',
  [AddQuoteOtherSpecialServicesInternationalFieldsNames.inBondCustomsRelForms]: 'otherServicesInBondCustomsRelForms',
  [AddQuoteOtherSpecialServicesInternationalFieldsNames.sufferanceNbOnQc]: 'otherServicesSufferanceNbOnQc',
  [AddQuoteOtherSpecialServicesInternationalFieldsNames.sufferanceOther]: 'otherServicesSufferanceOther',
  [AddQuoteOtherSpecialServicesInternationalFieldsNames.sufferanceRemanifest]: 'otherServicesSufferanceRemanifest',
  [AddQuoteOtherSpecialServicesInternationalFieldsNames.sufferanceHandling]: 'otherServicesSufferanceHandling',
  [AddQuoteOtherSpecialServicesInternationalFieldsNames.sufferanceExamination]: 'otherServicesSufferanceExamination',
  [AddQuoteOtherSpecialServicesInternationalFieldsNames.sufferanceInspectionDelay]:
    'otherServicesSufferanceInspectionDelay',

  [AddQuoteOtherSpecialServicesMiscellaneousFieldsNames.instantGuaranteedService]:
    'otherServicesInstantGuaranteedService',
  [AddQuoteOtherSpecialServicesMiscellaneousFieldsNames.guaranteedByNoon]: 'otherServicesGuaranteedByNoon',
  [AddQuoteOtherSpecialServicesMiscellaneousFieldsNames.blindShipment]: 'otherServicesBlindShipment',
  [AddQuoteOtherSpecialServicesMiscellaneousFieldsNames.correctiontoBol]: 'otherServicesCorrectiontoBol',
  [AddQuoteOtherSpecialServicesMiscellaneousFieldsNames.codCancelChange]: 'otherServicesCodCancelChange',
  [AddQuoteOtherSpecialServicesMiscellaneousFieldsNames.returnUndeliveredShipment]:
    'otherServicesReturnUndeliveredShipment',
  [AddQuoteOtherSpecialServicesMiscellaneousFieldsNames.returnCheck]: 'otherServicesReturnCheck',
  [AddQuoteOtherSpecialServicesMiscellaneousFieldsNames.rapidRemoteService]: 'otherServicesRapidRemoteService',
  [AddQuoteOtherSpecialServicesMiscellaneousFieldsNames.excessiveLength]: 'otherServicesExcessiveLength',
  [AddQuoteOtherSpecialServicesMiscellaneousInputFieldsNames.excessiveLengthList]: 'otherServicesExcessiveLengthList',
  [AddQuoteOtherSpecialServicesMiscellaneousFieldsNames.excessiveValueCharge]: 'otherServicesExcessiveValueCharge',
  [AddQuoteOtherSpecialServicesMiscellaneousInputFieldsNames.excessiveValueChargeValue]:
    'otherServicesExcessiveValueChargeValue',
  [AddQuoteOtherSpecialServicesMiscellaneousFieldsNames.freezableProtection]: 'otherServicesFreezableProtection',
};

export const AddQuoteOtherSpecialServicesFirstGroupListAttributes = {
  [AddQuoteOtherSpecialServicesInternationalFieldsNames.sufferanceNbOnQc]: true,
  [AddQuoteOtherSpecialServicesMiscellaneousFieldsNames.blindShipment]: true,
  [AddQuoteOtherSpecialServicesMiscellaneousFieldsNames.returnUndeliveredShipment]: true,
  [AddQuoteOtherSpecialServicesMiscellaneousFieldsNames.excessiveLength]: true,
  [AddQuoteOtherSpecialServicesMiscellaneousFieldsNames.excessiveValueCharge]: true,
};

export const AddQuoteOtherSpecialServicesTooltipIds = {
  [AddQuoteOtherSpecialServicesMiscellaneousFieldsNames.instantGuaranteedService]:
    'otherServicesInstantGuaranteedServiceTooltip',
  [AddQuoteOtherSpecialServicesMiscellaneousFieldsNames.blindShipment]: 'otherServicesBlindShipmentTooltip',
  [AddQuoteOtherSpecialServicesMiscellaneousFieldsNames.codCancelChange]: 'otherServicesCodCancelChangeTooltip',
  [AddQuoteOtherSpecialServicesMiscellaneousFieldsNames.returnUndeliveredShipment]:
    'otherServicesReturnUndeliveredShipmentTooltip',
  [AddQuoteOtherSpecialServicesMiscellaneousFieldsNames.returnCheck]: 'otherServicesReturnCheckTooltip',
  [AddQuoteOtherSpecialServicesMiscellaneousFieldsNames.freezableProtection]: 'otherServicesFreezableProtectionTooltip',
  [AddQuoteOtherSpecialServicesMiscellaneousFieldsNames.rapidRemoteService]: 'otherServicesRapidRemoteServiceTooltip',
  [AddQuoteOtherSpecialServicesInternationalFieldsNames.inBondFreight]: 'otherServicesInBondFreightTooltip',
  [AddQuoteOtherSpecialServicesInternationalFieldsNames.inBondTirCarnet]: 'otherServicesInBondTirCarnetTooltip',
};

// Comments #################################################################################################
export const AddQuoteCommentsIds = {
  [AddQuoteFormFieldsNames.comments]: 'commentsAddQuote',
  hint: 'commentsAddQuoteHint',
};

// ACCOUNT TRANSPORT INFORMATION ##################################################################################
export enum AddQuoteAccountTransportInformationFormFieldsNames {
  city = 'city',
  ship = 'ship',
  postalCode = 'postalCode',
}

export const AddQuoteAccountTransportInformationIds = {
  [AddQuoteAccountTransportInformationFormFieldsNames.city]: 'cityTransport',
  [AddQuoteAccountTransportInformationFormFieldsNames.ship]: 'shipTransport',
  shipToDestination: 'shipToDestinationTransport',
  shipFromOrigin: 'shipFromOriginTransport',
  [AddQuoteAccountTransportInformationFormFieldsNames.postalCode]: 'postalCodeTransport',
  postalCodeError: 'postalCodeTransportError',
};

export enum AddQuoteAccountTransportationDefaultValue {
  city = 'empty',
}

// ACCOUNT INFORMATION ############################################################################################
export enum AddQuoteAccountInformationFormFieldsNames {
  name = 'name',
  address = 'address',
  accountMdCode = 'accountMdCode',
  paymentTerms = 'paymentTerms',
}

export interface AddQuoteAccountInformationFormObject {
  [AddQuoteAccountInformationFormFieldsNames.name]: any;
  [AddQuoteAccountInformationFormFieldsNames.address]: any;
  [AddQuoteAccountInformationFormFieldsNames.accountMdCode]: any;
  [AddQuoteAccountTransportInformationFormFieldsNames.city]: any;
  [AddQuoteAccountTransportInformationFormFieldsNames.ship]: any;
  [AddQuoteAccountTransportInformationFormFieldsNames.postalCode]?: any;
  [AddQuoteAccountInformationFormFieldsNames.paymentTerms]: any;
}

export const AddQuoteAccountInformationIds = {
  [AddQuoteAccountInformationFormFieldsNames.name]: 'nameAccount',
  [AddQuoteAccountInformationFormFieldsNames.address]: 'addressAccount',
  [AddQuoteAccountInformationFormFieldsNames.accountMdCode]: 'accountMdCodeAccount',
  [AddQuoteAccountTransportInformationFormFieldsNames.city]: AddQuoteAccountTransportInformationIds.city,
  [AddQuoteAccountTransportInformationFormFieldsNames.ship]: AddQuoteAccountTransportInformationIds.ship,
  [AddQuoteAccountInformationFormFieldsNames.paymentTerms]: 'paymentTermsAccount',
  prepaid: 'prepaidPaymentAccount',
  collect: 'collectAccount',
};

// Ship #################################################################################################################

export enum ShipOptionText {
  toDestination = 'Ship to Destination',
  fromOrigin = 'Ship from Origin',
}

export const ShipOptions: ShipOptionsInterface = {
  toDestination: {
    value: ChargeToCd.PPD,
    text: ShipOptionText.toDestination,
  },
  fromOrigin: {
    value: ChargeToCd.COLL,
    text: ShipOptionText.fromOrigin,
  },
};

// Special Services International #####################################################################################################
export enum AddQuoteOtherSpecialServicesInternationalFormFieldsNames {
  specialServicesInternationalCol1 = 'specialServicesInternationalCol1',
  specialServicesInternationalCol2 = 'specialServicesInternationalCol2',
}

export interface AddQuoteSpecialServicesInternationalFormObject {
  [AddQuoteOtherSpecialServicesInternationalFormFieldsNames.specialServicesInternationalCol1]: any;
  [AddQuoteOtherSpecialServicesInternationalFormFieldsNames.specialServicesInternationalCol2]: any;
}

export enum AddQuoteOtherSpecialServicesInternationalInternationalFieldsNames {
  borderCrossingService = 'DRA',
}

export const AddQuoteSpecialServicesInternationalListText = {
  [AddQuoteCmmodityInformationFormFieldsNames.singleShipment]: 'Single Shipment',
  [RateQuoteOtherAccessorialCode.hazmat]: 'HazMat',
  [AddQuoteDeliverySpecialServicesCommonFieldsNames.notification]: 'Notification Prior to Delivery	',
  [AddQuoteDeliverySpecialServicesCommonFieldsNames.residential]: 'Residential Delv',
  [AddQuoteDeliverySpecialServicesCommonFieldsNames.inside]: 'Inside Delv',
  [AddQuoteDeliverySpecialServicesCommonFieldsNames.liftGateService]: 'Lift Gate Service Delv',
  [AddQuoteDeliverySpecialServicesCommonFieldsNames.holidayWeekend]: 'Holiday/Wkend Delv',
  [AddQuoteDeliverySpecialServicesCommonFieldsNames.construction]: 'Construction/Util Delv',
  [AddQuoteDeliverySpecialServicesCommonFieldsNames.sorting]: 'Sorting/Segregating Delv',
  [AddQuotePickupSpecialServicesCommonFieldsNames.inside]: 'Inside P/U',
  [AddQuotePickupSpecialServicesCommonFieldsNames.liftGateService]: 'Lift Gate Service P/U',
  [AddQuotePickupSpecialServicesCommonFieldsNames.residential]: 'Residential P/U',
  [AddQuotePickupSpecialServicesCommonFieldsNames.sorting]: 'Sorting/Segregating P/U',
  [AddQuoteOtherSpecialServicesInternationalFieldsNames.inBondFreight]: 'In Bond Freight',
  [AddQuoteOtherSpecialServicesInternationalInternationalFieldsNames.borderCrossingService]: 'Border Crossing Service',
};

// International Shipping Information ########################################################################################################
export interface AddQuoteInternationalShippingInformationFormObject {
  [AddQuotePaymentTermsFormFieldsNames.discountLevel]: any;
  [AddQuotePaymentTermsFormFieldsNames.tariff]: any;
  [AddQuoteAccountTransportInformationFormFieldsNames.city]: any;
  [AddQuoteAccountTransportInformationFormFieldsNames.ship]: any;
  [AddQuoteAccountTransportInformationFormFieldsNames.postalCode]: any;
}

export const AddQuoteInternationalShippingInformationTermsIds = {
  [AddQuotePaymentTermsFormFieldsNames.discountLevel]: 'internationalShippingDiscountLevel',
  [AddQuotePaymentTermsFormFieldsNames.tariff]: 'internationalShippingTariff',
};

export const AddQuoteInternationalShippingInformationTermsErrorIds = {
  [AddQuotePaymentTermsFormFieldsNames.discountLevel]: 'internationalShippingDiscountLevelError',
  [AddQuotePaymentTermsFormFieldsNames.tariff]: 'internationalShippingTariffError',
};

// International Commodity Information ##################################################################################################
export enum AddQuoteInternationalVolume {
  cubicMeters = 'cubicMeters',
  cubicFeet = 'cubicFeet',
}
export interface AddQuoteInternationalCommodityDimentionListInterface {
  weight: RateQuoteSelectInterface[];
  volume: RateQuoteSelectInterface[];
}

export const AddQuoteInternationalCommodityDimentionList: AddQuoteInternationalCommodityDimentionListInterface = {
  weight: [{ value: WeightUomCd.POUNDS, text: 'lbs' }, { value: WeightUomCd.KILOGRAMS, text: 'kgs' }],
  volume: [
    { value: AddQuoteInternationalVolume.cubicFeet, text: 'cubic feet' },
    { value: AddQuoteInternationalVolume.cubicMeters, text: 'cubic meters' },
  ],
};

export enum AddQuoteInternationalCommodityInformationFormFieldsNames {
  commodityList = 'commodityList',
  anticipatedShippingDate = 'anticipatedShippingDate',
  weightDimention = 'weightDimention',
  volumeDimention = 'volumeDimention',
}

export enum AddQuoteInternationalCommodityListInformationFormFieldsNames {
  class = 'class',
  weight = 'weight',
  volume = 'volume',
  commodity = 'commodity',
  nmfcCode = 'nmfcCode',
}

export interface AddQuoteInternationalCommodityInformationFormObject {
  [AddQuoteInternationalCommodityInformationFormFieldsNames.commodityList]: any;
  [AddQuoteInternationalCommodityInformationFormFieldsNames.anticipatedShippingDate]: any;
  [AddQuoteInternationalCommodityInformationFormFieldsNames.weightDimention]: any;
  [AddQuoteInternationalCommodityInformationFormFieldsNames.volumeDimention]: any;
}

export interface AddQuoteInternationalCommodityInformationListFormObject {
  [AddQuoteInternationalCommodityListInformationFormFieldsNames.class]: any;
  [AddQuoteInternationalCommodityListInformationFormFieldsNames.weight]: any;
  [AddQuoteInternationalCommodityListInformationFormFieldsNames.volume]: any;
  [AddQuoteInternationalCommodityListInformationFormFieldsNames.commodity]: any;
  [AddQuoteInternationalCommodityListInformationFormFieldsNames.nmfcCode]: any;
}

export const AddQuoteInternationalCommodityInformationIds = {
  [AddQuoteInternationalCommodityInformationFormFieldsNames.anticipatedShippingDate]: 'anticipatedShippingDate',
  [AddQuoteInternationalCommodityInformationFormFieldsNames.weightDimention]: 'anticipatedShippingDate',
  [AddQuoteInternationalCommodityInformationFormFieldsNames.volumeDimention]: 'anticipatedShippingDate',
  addAnhotherCommodity: 'addAnhotherCommodity',
};

export const AddQuoteInternationalCommodityInformationListIds = {
  [AddQuoteInternationalCommodityListInformationFormFieldsNames.class]: 'classInternationalQuoteCommodityList',
  [AddQuoteInternationalCommodityListInformationFormFieldsNames.weight]: 'weightInternationalQuoteCommodityList',
  [AddQuoteInternationalCommodityListInformationFormFieldsNames.volume]: 'volumeInternationalQuoteCommodityList',
  [AddQuoteInternationalCommodityListInformationFormFieldsNames.commodity]: 'commodityInternationalQuoteCommodityList',
  [AddQuoteInternationalCommodityListInformationFormFieldsNames.nmfcCode]: 'nmfcCodeInternationalQuoteCommodityList',
};
