import { SearchFormValidation } from '@shared/enums/search-form-validations.enum';

export const messagesConstant = {
  whatIfRequests: {
    deleteRequests: `Deleting the requests will delete all the data associated with it.<br/><br/>Once deleted, the selected requests cannot be recovered.`,
    deleteRequestsWrongStatusPlural: `There are {requestNumber} requests that cannot be deleted because they are in ‘Submitted’, ‘In Progress’ or On Hold’ statuses.<br/><br/>Deleting the What If requests will delete all the data associated with it. Once deleted, the requests cannot be recovered.<br/><br/>Do you want to delete selected What If requests that can be deleted?`,
    deleteRequestsWrongStatusSingular: `There is {requestNumber} request that cannot be deleted because it is in ‘Submitted’, ‘In Progress’ or On Hold’ status.<br/><br/>Deleting the What If requests will delete all the data associated with it. Once deleted, the requests cannot be recovered.<br/><br/>Do you want to delete selected What If requests that can be deleted?`,
    deleteRequestsWrongUserPlural: `There are {requestNumber} requests that cannot be deleted because they are created by other users.<br/><br/>Deleting the What If requests will delete all the data associated with it. Once deleted, the requests cannot be recovered.<br/><br/>Do you want to delete selected What If requests that can be deleted?`,
    deleteRequestsWrongUserSingular: `There is {requestNumber} request that cannot be deleted because it is created by other user.<br/><br/>Deleting the What If requests will delete all the data associated with it. Once deleted, the requests cannot be recovered.<br/><br/>Do you want to delete selected What If requests that can be deleted?`,
    deleteRequestsTitle: 'Do you want to delete selected What If requests?',
    deleteRequestsTitleWrongStatus: 'Few of the selected requests cannot be deleted',
    deleteRequestsSuccess: 'Your requests have been deleted successfully.',
    deleteRequestsFail: 'Error: Your requests have not been deleted.',
    getRequestsError: `There was an error loading results. Please check your internet connection or try again later`,
    getUsersRequestersFail: `There was an error getting the requesters.`,
    addFileWithWrongExtension: `The file extension must be csv.`,
    addFileWithErrorFormat: `The CSV file has formatting errors in the rows: {rows}`,
    addFileWithErrorEmptyFile: `The File is empty. Please check correct file is attached`,
    addStartDateGreaterEndDate: `The start date must not be greater than the end date`,
    addRequestSuccessfuly: `Your request {requestId} for What If analysis has been submitted successfully`,
    addFileServiceError: `Your request submission was not successful`,
    pickupDateFromMinDateError: `The selected date cannot be less than three years`,
    nmfcError: `The nmfc value is wrong`,
    shipmentFileWrongName: `The shipment file name is invalid`,
    shipmentFileDownloadError: `There were some issues downloading the csv file. Please try again later`,
    shipmentFileTemplateDownloadError: `There were some issues downloading the csv file template. Please try again later`,
    getAccountsFromServiceError: `There were some issues getting the accounts`,
    getRequestersError: `There were some issues getting the requesters. Please try again later`,
    getRequestsDetailError: `There were some issues getting the request detail. Please try again later`,
    getAgreementIdError: `There were some issues getting the agreement id. Please try again later`,
    deleteRequestsServiceError: `There were some issues deleting the selected requests`,
    closeBackDropTitle: `Do you want to continue editing?`,
    closeBackDrop: `You have unsaved information on this page. Leaving without adding the request will remove all changes.`,
    invalidShipmentSelection: `The shipment selection {shipmentSelection} is invalid`,
    formFields: {
      validAccount: 'Enter a valid account or CIS number',
      validAgreement: 'Enter a valid agreement ID',
      validPickupStart: 'Enter a valid pickup start',
      validPickupEnd: 'Enter a valid pickup to',
      validFile: 'Enter a valid csv file',
      validTariff: 'Enter a valid tariff',
      emptyTariff: 'Enter the Tariff',
    },
  },
  ratingAnalysis: {
    apiResponse: {
      getProNumberDetailsError: 'It was not possible to get the PRO number details, please try again later',
      getProNumberDetailBreakdownError:
        'It was not possible to get the PRO number detail breakdown, please try again later',
    },
  },
  rateQuote: {
    apiResponse: {
      getQuotes: 'It was not possible to get the quotes, try again later',
      getQuotesDetailFileError: 'It was not possible to get the detail quote file, try again later',
      getQuotesDetailError: 'It was not possible to get the detail quote, please try again later',
      getRateQuoteForShipmentError: 'It was not possible to get the PRO Number detail, please try again later',
      createRateQuoteError: 'It was not possible to create a New Rate Quote',
      createSpotQuoteError: 'It was not possible to create the Spot Quote',
      createInternationalRateQuoteError: 'It was not possible to create a New International Rate Quote',
      restrictedAccountCustomerNotFoundInCRM: 'Customer not found in CRM.',
    },
    addQuote: {
      excessiveValueError: `Invalid value`,
      totalPalletTooltipCustomerSpecific: `If you have pallet pricing you must fill out the Pallet Count field in order to receive the rate`,
      totalPalletTooltipGeneric: `Simple Flat Rate: pallet count required (max of 6 pallets and may not exceed 48x48x48)`,
      totalPalletInfoCustomerSpecific: `If an account has pallet rates that might apply then this field is required`,
      totalPalletInfoGeneric: `For pallet rate, enter SFR599 special tariff and enter the number of pallets (required)`,
      totalCommodityWeightError: `Total Commodity Weight must be less than 100,000 lbs. For quotes at this weight, contact your Service Center for assistance.`,
      totalCommodityWeightGuaranteedByNoonError: `Total Commodity Weight must be less than 20000 lbs when Guaranteed by Noon (G!12) is selected`,
      totalWeightWarningGratherThanTitle: `Confirm Capacity`,
      totalWeightWarningGratherThan: `Please check with your local service center to confirm capacity for any commodity over 25000 lbs.`,
      totalWeightWarningLessThanTitle: `Single Shipment Reminder`,
      totalWeightWarningLessThan: `Single shipments less than 500 lbs may be subject to a special service charge.`,
      totalWeightWarningPerUnitsAverageTitle: `Confirm Capacity`,
      totalWeightWarningPerUnitsAverage: `A shipping unit should not exceed 4500 pounds for safe handling via XPO.`,
      totalWeightWarningPerUnitsAverageInternational: `A shipping unit should not exceed 4500 pounds (2041 kilograms) for safe handling via XPO.`,
      heightTariffWarning: 'Height of 75 inches or more may be subject to CNWY 199 Tariff Item 26.',
      akHiPrOriginRuleTooltip: '{fieldName} cannot be applied when the Origin Postal code belongs to {location}',
      akHiPrDestRuleTooltip: '{fieldName} cannot be applied when the Destination Postal code belongs to {location}',
      guaranteedByNoonServiceLiftGate: `Guaranteed by Noon (G!12) cannot be applied when Lift Gate Service is selected`,
      instantGuaranteedServiceLiftGate: `Instant Guaranteed Service (G!) cannot be applied when Lift Gate Service is selected`,
      liftGateGuaranteedByNoonService: `Lift Gate Service cannot be applied when Guaranteed by Noon (G!12) is selected`,
      guaranteedByNoonExhibition: `Exhibition cannot be applied when Guaranteed by Noon (G!12) is selected`,
      exhibitionGuaranteedByNoon: `Guaranteed by Noon (G!12) cannot be applied when Exhibition is selected`,
      timeDateExhibition: `Exhibition cannot be applied when Time / Date Critical is selected`,
      exhibitionTimeDate: `Time / Date Critical cannot be applied when Exhibition is selected`,
      rapidRemoteServiceLiftGate: `Rapid Remote Service is not available when a destination Lift Gate is required`,
      liftGateRapidRemoteService: `Lift Gate Service cannot be applied when Rapid Remote Service is selected`,
      rapidRemoteServiceHazmat: `Hazmat cannot be applied when Rapid Remote Service is selected`,
      hazmatRapidRemoteService: `Rapid Remote Service cannot be applied when Hazmat is selected`,
      guaranteedByNoonRapidRemoteService: `Rapid Remote Service cannot be applied when Guaranteed by Noon (G!12) is selected`,
      rapidRemoteServiceGuaranteedByNoon: `Guaranteed by Noon (G!12) cannot be applied when Rapid Remote Service is selected`,
      guaranteedByNoonInBondFreight: `In Bond Freight cannot be applied when Guaranteed by Noon (G!12) is selected`,
      inBondFreightGuaranteedByNoon: `Guaranteed by Noon (G!12) cannot be applied when In Bond Freight is selected`,
      guaranteedByNoonInBondTirCarnet: `In Bond TIR Carnet cannot be applied when Guaranteed by Noon (G!12) is selected`,
      inBondTirCarnetGuaranteedByNoon: `Guaranteed by Noon (G!12) cannot be applied when In Bond TIR Carnet is selected`,
      instantGuaranteedInBondTirCarnet: `In Bond TIR Carnet cannot be applied when Instant Guaranteed Service (G!) is selected`,
      inBondTirCarnetInstantGuaranteed: `Instant Guaranteed Service (G!) cannot be applied when In Bond TIR Carnet is selected`,
      guaranteedByNoonInBondCustomsInspectionDelay: `In Bond Customs Inspection Delay cannot be applied when Guaranteed by Noon (G!12) is selected`,
      inBondCustomsInspectionDelayGuaranteedByNoon: `Guaranteed by Noon (G!12) cannot be applied when In Bond Customs Inspection Delay is selected`,
      instantGuaranteedInBondCustomsInspectionDelay: `In Bond Customs Inspection Delay cannot be applied when Instant Guaranteed Service (G!) is selected`,
      inBondCustomsInspectionDelayInstantGuaranteed: `Instant Guaranteed Service (G!) cannot be applied when In Bond Customs Inspection Delay is selected`,
      guaranteedByNoonInBondCustomsRelForm: `In Bond Customs Rel. Forms cannot be applied when Guaranteed by Noon (G!12) is selected`,
      inBondCustomsRelFormGuaranteedByNoon: `Guaranteed by Noon (G!12) cannot be applied when In Bond Customs Rel. Forms is selected`,
      instantGuaranteedInBondCustomsRelForm: `In Bond Customs Rel. Forms cannot be applied when Instant Guaranteed Service (G!) is selected`,
      inBondCustomsRelFormInstantGuaranteed: `Instant Guaranteed Service (G!) cannot be applied when In Bond Customs Rel. Forms is selected`,
      guaranteedByNoonPickupExhibition: `Exhibition cannot be applied when Guaranteed by Noon (G!12) is selected`,
      pickupExhibitionGuaranteedByNoon: `Guaranteed by Noon (G!12) cannot be applied when Special Pickup Services Exhibition is selected`,
      hazmatGuaranteedByNoon: `Guaranteed by Noon (G!12) cannot be applied when Hazmat is selected`,
      guaranteedByNoonHazmat: `Hazmat cannot be applied when Guaranteed by Noon (G!12) is selected`,
      inBondFreightInstantGuaranteed: `Instant Guaranteed Service (G!) cannot be applied when In Bond Freight is selected`,
      instantGuaranteedInBondFreight: `In Bond Freight cannot be applied when Instant Guaranteed Service (G!) is selected`,
      instantGuaranteedDeliveryLiftGate: `Lift Gate Service cannot be applied when Instant Guaranteed Service (G!) is selected`,
      instantGuaranteedSufferanceNbOnQc: `Sufferance (Canada): NB, ON, QC cannot be applied when Instant Guaranteed Service (G!) is selected`,
      sufferanceNbOnQcInstantGuaranteed: `Instant Guaranteed Service (G!) cannot be applied when Sufferance (Canada): NB, ON, QC is selected`,
      instantGuaranteedSufferanceOther: `Sufferance (Canada): Other cannot be applied when Instant Guaranteed Service (G!) is selected`,
      sufferanceOtherInstantGuaranteed: `Instant Guaranteed Service (G!) cannot be applied when Sufferance (Canada): Other is selected`,
      instantGuaranteedSufferanceRemanifest: `Sufferance Re-manifest cannot be applied when Instant Guaranteed Service (G!) is selected`,
      sufferanceRemanifestInstantGuaranteed: `Instant Guaranteed Service (G!) cannot be applied when Sufferance Re-manifest is selected`,
      instantGuaranteedSufferanceHandling: `Sufferance Handling cannot be applied when Instant Guaranteed Service (G!) is selected`,
      sufferanceHandlingInstantGuaranteed: `Instant Guaranteed Service (G!) cannot be applied when Sufferance Handling is selected`,
      instantGuaranteedSufferanceExamination: `Sufferance Examination cannot be applied when Instant Guaranteed Service (G!) is selected`,
      sufferanceExaminationInstantGuaranteed: `Instant Guaranteed Service (G!) cannot be applied when Sufferance Examination is selected`,
      instantGuaranteedSufferanceInspectionDelay: `Sufferance Inspection Delay cannot be applied when Instant Guaranteed Service (G!) is selected`,
      sufferanceInspectionDelayInstantGuaranteed: `Instant Guaranteed Service (G!) cannot be applied when Sufferance Inspection Delay is selected`,
      rateQuoteCouldNotBeCalculatedTitle: `Rate Quote could not be calculated`,
      rateQuoteCouldNotBeCalculatedResults: `Results: Rate Quote could not be calculated automatically on-line.`,
      rateQuoteTotalCubicFeetRuleMessage: `Cubic feet must be numeric, greater than zero and up to two decimal places`,
      freezableProtectionInfo: `Freezable Protection Service offered October 1 through April 30`,
      excessiveValueChargeInfo: `Excess Value Coverage is optional. If you would like your shipment to be covered at a higher value , enter the excess value amount. For more information, please refer to item 227 in Tariff CNWY-199.`,
      internationalHazmatAccesorial: `For HazMat quotes to Mexico, please email us at LTL.Mexico@xpo.com, or call (866) 431-0777. On-line quotes will not apply until validated by the Mexico Support Center. MSDS sheets are required to obtain validation and quote.`,
      internationalEmailCitySubject: `International Quote for Mexico to/from US/CN`,
      minHeavyFreightError: `Number of Units required when weight is greater than 4500 lbs.`,
      internalReminders: {
        copyQuote: 'Would you like copy of this quote?',
        schedulePickup: 'Ask for the shipment – “May I schedule this pickup for you today?“',
        shipmentGreaterThanLinealFoot:
          'If shipment is greater than 14 lineal foot, we will need to rerate this shipment with dimensions.',
      },
    },
    addQuoteResult: {
      spotQuoteShipDescription: `Quote is good until`,
      titleLongDescriptionG12: `Receive superior service performance and delivery by noon to select destinations up to 25 miles from one of our local service centers.`,
      titleLongDescriptionGUR: `Receive unmatched service and handling to ensure delivery by end-of-day for the lowest flat fee in the industry.`,
      specialPricing: 'Special Pricing (XSS)',
      schedulePickupRequestBtnDisableText: 'This option is only available for customer-specific rate quotes.'
    },
    addInternationalQuote: {
      accesorialRules: {
        insideDelivery: `Inside Delivery cannot be selected for destination MX`,
        holidayWeekendDelivery: `Holiday/Weekend Delivery cannot be selected for destination MX`,
        insidePickup: `Inside Pickup cannot be selected for origin MX`,
      },
    },
    general: {
      restrictedAccount: {
        message: 'This is a Restricted 3PL. Do not share charges with any party other than a 3PL representative.',
        specialReminder: 'Special Internal reminder:',
      },
    },
    grid: {
      createDateRangeError: `The create date range cannot exceed 90 days`,
      dateRangeErrorModalTitle: `Create Date Range Error`,
    },
    search: {
      customerSpecific: {
        atLeastOne: 'At least one Customer Name or Account/Madcode is required.',
      },
      quoteLookup: {
        invalidCnfmNbr: 'Invalid confirmation number',
        noData: 'No data found for the confirmation number entered',
      },
      thirdPartyLogisticsAccount: 'ACCOUNTS PAYABLE at (800) 326-9977',
    },
    rerun: {
      customerEmpty: `The Rate quote type is for Customer but the Customer list is empty`,
    },
    spotQuote: {
      spotQuoteDialogTitle: `Spot Quote`,
      spotQuoteDialogDescription: `This shipment may qualify for additional savings. If interested, please request a volume shipment Spot Quote.`,
    },
    invalidZipCodeForMabd: {
      title: `Invalid zip code for MABD`,
      description: `Invalid zip code for MABD due to indirect service point.`,
      confirmBtn: `OK`
    }
  },
  regex: {
    allowFileExtensionsRegex: {
      empty: 'The extensions and fileName are required.',
    },
  },
  general: {
    errorTitle: 'Error',
    invalidPostalCode: 'Invalid postal code',
    invalidCurrency: 'Invalid currency',
    invalidTariff: 'Invalid tariff',
    invalidCustomer: 'Enter a valid Account Code/Number.',
    invalidCustomerType: `Customer {code} is a corporate or future location. Please enter a pickup/delivery or bill-to account code.`,
    invalidValue: 'Invalid value',
    downloadTemplateEmptyHeadersError: `Headers are needed for {fileName}, it is neccesary to configure it`,
    downloadTemplateEmptyNameError: `The download template file must have a name`,
    transformDataFunctionError: `{functionName}, The object must not be empty`,
    transformDataGetAgreementIdError: `GetAgreementId method needs all input value to return a correct agreement id`,
    serviceError: `Sorry, there seems to be an error.`,
    getTariffListServiceError: `There were some issues getting the list of tariffs. Please try again later`,
    getTariffListZoneCitiesError: `It was not possible to get the list of cities, please try again later.`,
    invalidFormRequiredField: 'Required',
    sendEmailApiError: `It was not possible to send the email, please try again later`,
    locationApiError: `Location API has failed, please try again later`,
    sendEmailFailTitle: `Error Sending Email`,
    forEmailRequiredMessage: `An email is required`,
    duplicatedEmail: `Email is duplicated`,
    sendEmailSuccess: `The email has been sent successfully`,
    customerApi: {
      getCustomerLocationFromServiceError: `There were some issues getting the customer data`,
    },
    pricingApi: {
      getPricingAgreementError: 'It was not possible to get the Pricing Agreement, please try again later',
      getPricingAgreementShortError: 'Agreement ({agreementId}) not found',
      getCorporateAccessorials: 'It was not possible to get the Corporate Accessorials, please try again later',
    },
    formValidationMessages: {
      proNumber: {
        [SearchFormValidation.proNumberLengthValidation]: `Expected PRO number of 9-11 characters`,
        [SearchFormValidation.proNumberNineValidation]: `Expected PRO number of 9 digits (no letters or special characters)`,
        [SearchFormValidation.proNumberTenValidation]: `Expected PRO number of 10 characters to be in the format 'nnn‑nnnnnn'`,
        [SearchFormValidation.proNumberElevenValidation]: `Expected PRO number of 11 digits to be in the format '0nnn0nnnnnn'`,
        required: `The PRO number is required`,
      },
    },
  },
  manualRating: {
    dialog: {
      idleTitle: 'Your work session will end in 5 minutes',
      idleMessage:
        'This session appears to be inactive. If it remains inactive the PRO will be unlocked and you will be returned to the dashboard.',
    },
    formValidationMessages: {
      required: 'This field is required',
      notFound: 'Enter a valid PRO / Queue Name',
      emptyQueue: 'The queue is empty',
      duplicatedAccessorial: 'The accessorial code is already selected',
      invalidAccessorial: 'Select a valid accessorial',
      invalidAccessorialDate: 'AC not effective for Pickup Date',
      startGreaterThanEndError: 'End date must be on or after start date',
      dateRangeError: 'Maximum range of one week is allowed',
    },
    apiResponse: {
      downloadUnprocessedProsCsvError: 'It was not possible to download the file, try again later',
      getListProsByUser: 'It was not possible to get the PRO list, try again later',
      getManualRatingSummary: 'It was not possible to get the data, try again later',
      getShipmentForManualRatingDateError: 'Something is wrong with the returned data',
      getNextItemFromQueueError: 'Something is wrong getting the next PRO, try again later',
      lockProError: 'Something is wrong locking the PRO, try again later',
      unlockProError: 'Something is wrong unlocking the PRO, try again later',
      calculateManualRatingError: 'Something is wrong with the calculate, try again later',
      releaseManualRatingError: 'Something is wrong with the release, try again later',
      releaseManualRatingSuccess: 'PRO number {proNumber} was released successfully',
    },
    errors: {
      anticipatedShippingDateNullRerun: `The attribute inEffectiveDate is empty`,
    },
    tooltips: {
      manualRatePro: {
        rate: `• AC charge by CWT
        • FSC charge by % discounted linehaul`,
      },
    },
  },
  mrra: {
    apiResponse: {
      createMassRerateError: 'It was not possible to create a New Mass Rerate request',
    },
    addFileWithWrongExtension: `The file extension must be csv.`,
    addFileWithErrorFormat: `The CSV file has formatting errors in the rows: {rows}`,
    addFileWithErrorEmptyFile: `The File is empty. Please check correct file is attached`,
    addFileSuccess: `MRRA request successfully submitted.`,
    addFileFail: `File not successfully uploaded`,
  }
};
